import { defineAsyncComponent } from 'vue';

// Common Modals
export const ErrorModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Common/ErrorModal.vue')
);
export const InformationModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Common/InformationModal.vue')
);
export const LoadingModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Common/LoadingModal.vue')
);
export const ModalContainer = defineAsyncComponent(
  () => import('@/components/custom/Modals/Common/ModalContainer.vue')
);
export const SuccessModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Common/SuccessModal.vue')
);
export const WarningModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Common/WarningModal.vue')
);

// Table Modals
export const InvalidDataModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Common/InvalidDataModal.vue')
);
export const DeleteEntityModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Common/DeleteEntityModal.vue')
);

// Accounts Modals
export const CreateEditAccountModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Accounts/CreateEditAccountModal/CreateEditAccountModal.vue'
    )
);
export const ViewAccountModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Accounts/ViewAccountModal/ViewAccountModal.vue'
    )
);
export const BulkChangeUserLoginTypeModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Accounts/BulkActions/BulkChangeUserLoginTypeModal.vue'
    )
);
export const BulkChangeUserStatusModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Accounts/BulkActions/BulkChangeUserStatusModal.vue'
    )
);
export const BulkChangeUserGroupsModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Accounts/BulkActions/BulkChangeUserGroupsModal.vue'
    )
);
export const BulkDeleteAccountsModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Accounts/BulkActions/BulkDeleteAccountsModal.vue'
    )
);
export const TriggerResetPasswordModal = defineAsyncComponent(
  () =>
    import('@/components/custom/Modals/Accounts/TriggerResetPasswordModal.vue')
);
export const SelectProductModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Accounts/SelectProductModal.vue')
);
export const BulkChangeUserProductAccessModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Accounts/BulkActions/BulkChangeUserProductAccess.vue'
    )
);

// Notes modals
export const AddEditNoteModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Accounts/AddEditNoteModal.vue')
);
export const BulkDeleteNotesModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Accounts/BulkActions/BulkDeleteNoteModal/BulkDeleteNotesModal.vue'
    )
);
export const BulkCreateNoteModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Accounts/BulkActions/BulkCreateNoteModal.vue'
    )
);

// Groups Modals
export const BulkAddToGroupsModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Groups/BulkActions/BulkAddToGroupsModal.vue'
    )
);
export const BulkDeleteGroupsModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Groups/BulkActions/BulkDeleteGroupsModal/BulkDeleteGroupsModal.vue'
    )
);
export const CreateEditGroupModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Groups/CreateEditGroupModal.vue')
);
export const ViewGroupModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Groups/ViewGroupModal/ViewGroupModal.vue'
    )
);

// Organization user preset Modals
export const CreateEditOrganizationUserPresetModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Organization/CreateEditOrganizationUserPresetModal/CreateEditOrganizationUserPresetModal.vue'
    )
);

// Tokens Modals
export const CreateEditTokenModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Tokens/CreateEditTokenModal.vue')
);

export const ViewTokenModal = defineAsyncComponent(
  () => import('@/components/custom/Modals/Tokens/ViewTokenModal.vue')
);

export const BulkDeleteTokenModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Tokens/BulkActions/BulkDeleteTokenModal/BulkDeleteTokenModal.vue'
    )
);

export const BulkChangeTokenStatusModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/Tokens/BulkActions/BulkChangeTokenStatusModal/BulkChangeTokenStatusModal.vue'
    )
);

// User Settings Modals
export const UpdateUserPasswordModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/UserSettings/UpdateUserPasswordModal.vue'
    )
);

export const PasswordConfirmationRequiredModal = defineAsyncComponent(
  () =>
    import(
      '@/components/custom/Modals/UserSettings/PasswordConfirmationRequiredModal.vue'
    )
);
